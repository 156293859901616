<span  *ngIf="display" (click)="close()" style="padding: 0px ; HEIGHT: 0PX;margin: 0px;" mat-dialog-title>
    <span  (click)="close()" fxLayout="row" fxLayoutAlign="end center">
               <button (click)="close()" mat-icon-button>
                   <mat-icon>close</mat-icon>
               </button>
           </span>
</span>

<div  *ngIf="!display"  (click)="close1222()"  style="width:100% ; height: auto">
    <div fxLayout="row" fxLayoutAlign="end center">
        <button (click)="close1222()" mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    </div>
<mat-dialog-content style=" MARGIN: 0PX;  max-height: 100%;
padding: 0px;">
   <div  fxLayout="row" (keydown.enter)="enterFunc()">
       <div fxHide fxShow.gt-sm fxShow.gt-sm class="left" fxLayout="column" fxLayoutAlign="space-between center"
           fxFlex="40">
           <div fxLayout="row" fxLayoutAlign="start none">
               <div class="login" *ngIf="disable!=true">
                   <span>Login</span>
                   <div class="msg">
                       <p>Get access to your Orders, Wishlist and Recommendations</p>
                   </div>
               </div>
               <div class="login" *ngIf="disable==true">
                   <span>Signup</span>
                   <div class="msg">
                       <span>We do not share your personal details with anyone.</span>
                   </div>
               </div>
           </div>
    <div fxLayout="row" class="img1" fxLayoutAlign="center center">
               <img src="assets/images/back.png" alt="login">
           </div>
       </div>
       <div fxFlex class="padding-10 margin-t20">
           <!-- <div class="left123"  fxLayout="row" fxLayoutAlign="end center">
               <button (click)="close()" mat-icon-button>
                   <mat-icon>close</mat-icon>
               </button>
           </div> -->
           <div class="padding-10"   fxLayoutAlign="center center" >
               <img style="max-width: 20%;" [src]="serverPath+'logoBig.png'" alt="logo">
           </div>
           <div class="signform" *ngIf="type=='signup'">
               <form [formGroup]="loginForm" autocomplete="off">
                   <div class="fields">
                       <mat-form-field class="w-100" appearance="outline">
                           <mat-label>{{title}}</mat-label>
                           <input autocomplete="off" name="q" style="font-size: initial;" appAutoFocus matInput
                               formControlName="username" required>
                           <mat-error  *ngFor="let validation of createUserValidations.username">
                               <mat-error class="error-message"
                                   *ngIf="loginForm.get('username').hasError(validation.type) && (loginForm.get('username').dirty || loginForm.get('username').touched) ">
                                   {{validation.message}}
                               </mat-error>
                           </mat-error>

                       </mat-form-field>

                       <div class="top">
                           <mat-form-field class="w-100" appearance="outline">
                               <mat-label>Password</mat-label>
                               <input matInput type="password" formControlName="password" required #passwordField>
                               <button mat-icon-button
                                   (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                                   tabindex="-1" matSuffix>
                                   <mat-icon *ngIf="passwordField.type === 'password'">visibility</mat-icon>
                                   <mat-icon *ngIf="passwordField.type === 'text'">visibility_off</mat-icon>
                               </button>
                               <mat-error *ngFor="let validation of createUserValidations.password">
                                   <mat-error class="error-message"
                                       *ngIf="loginForm.get('password').hasError(validation.type) && (loginForm.get('password').dirty || loginForm.get('password').touched) ">
                                       {{validation.message}}
                                   </mat-error>
                               </mat-error>
   
                           </mat-form-field>
                       </div>
 

                       <div fxLayout="column" fxLayoutGap="5px">
                           <div fxLayout="row" fxLayoutAlign="center center">
                               <small>By continuing, you agree to {{siteName |uppercase}}'s
                                   <a class="primary-colorr pointer" (click)="gotoCorresspondingPage('terms&conditions')" >Terms of
                                       Use</a> and
                                       <a (click)="gotoCorresspondingPage('privacy')" class="primary-colorr pointer"
                                       >Privacy Policy.</a>
                               </small>
                           </div>
                           <button (click)="login(loginForm.value)" fxFlex mat-flat-button class="login-button" >
                               Login
                           </button>
                           <div  fxLayout="column" *ngIf="otpEnable">
                           <div fxLayout="row"  fxLayoutAlign="center center">
                               -- OR --
                           </div>
                           <button (click)="exchange('otp')" fxFlex mat-stroked-button>
                               Request OTP
                           </button>

                       </div>
                       <div  fxLayout="column" *ngIf="googleLoginEnabled">
                           <div fxLayout="row"  fxLayoutAlign="center center">
                               -- OR --
                           </div>
                           <button  id="googleBtn" (click)="socialloginGoogle()" fxFlex mat-stroked-button>
                               Google Login
                           </button>

                       </div>

                       <div  fxLayout="column" *ngIf="twitterLoginEnabled">
                        <div fxLayout="row"  fxLayoutAlign="center center">
                            -- OR --
                        </div>
                        <button  id="googleBtn" (click)="TwitterLogin()" fxFlex mat-stroked-button>
                            Log in with Twitter
                        </button>

                    </div>
                       </div>
                   </div>

                   <!-- <div style="padding: 0px 50px;">
                       <div class="loginButton mat-elevation-z2" matRipple (click)="login(loginForm.value)" fxLayout="row"
                           fxLayoutAlign="center center">
                           <span>Login</span>
                       </div>
                   </div> -->

                   <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-2">
                       <div class="divider w-100"></div>
                       <!-- <h3 class="text-muted ">Sign in with</h3> -->
                       <div class="divider w-100"></div>
                   </div>

                <!-- <div class="text-center padding-20">
                       <svg class="social-icon" viewBox="0 0 24 24" matTooltip="Facebook">
                           <path
                               d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z" />
                       </svg>
                       <svg class="social-icon" viewBox="0 0 24 24" matTooltip="Twitter">
                           <path
                               d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M17.71,9.33C18.19,8.93 18.75,8.45 19,7.92C18.59,8.13 18.1,8.26 17.56,8.33C18.06,7.97 18.47,7.5 18.68,6.86C18.16,7.14 17.63,7.38 16.97,7.5C15.42,5.63 11.71,7.15 12.37,9.95C9.76,9.79 8.17,8.61 6.85,7.16C6.1,8.38 6.75,10.23 7.64,10.74C7.18,10.71 6.83,10.57 6.5,10.41C6.54,11.95 7.39,12.69 8.58,13.09C8.22,13.16 7.82,13.18 7.44,13.12C7.81,14.19 8.58,14.86 9.9,15C9,15.76 7.34,16.29 6,16.08C7.15,16.81 8.46,17.39 10.28,17.31C14.69,17.11 17.64,13.95 17.71,9.33Z" />
                       </svg>
                       <svg id="googleBtn" class="social-icon" viewBox="0 0 24 24" matTooltip="Google">
                           <path
                               d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M19.5,12H18V10.5H17V12H15.5V13H17V14.5H18V13H19.5V12M9.65,11.36V12.9H12.22C12.09,13.54 11.45,14.83 9.65,14.83C8.11,14.83 6.89,13.54 6.89,12C6.89,10.46 8.11,9.17 9.65,9.17C10.55,9.17 11.13,9.56 11.45,9.88L12.67,8.72C11.9,7.95 10.87,7.5 9.65,7.5C7.14,7.5 5.15,9.5 5.15,12C5.15,14.5 7.14,16.5 9.65,16.5C12.22,16.5 13.96,14.7 13.96,12.13C13.96,11.81 13.96,11.61 13.89,11.36H9.65Z" />
                       </svg>
                   </div>
    -->
                   <div fxLayout="row" fxLayoutAlign="center center" style="padding-bottom:px;">
                       <button (click)="exchange('register')" mat-button class="primary-icon">Create an account</button>/
                       <button (click)="exchange('forgotPassword')" mat-button class="primary-icon">Forgot Password</button>
                       <span *ngIf="referEarnEnable==1">/</span>
                       <button *ngIf="referEarnEnable==1" (click)="referAndEarn('register')" mat-button class="primary-icon">Refer and Earn</button>
                   </div>
                   <!-- <div fxLayout="row" fxLayoutAlign="center center">
             OR
           </div> -->

                   <!-- <div  fxLayout="row" fxLayoutAlign="center center">
             <button  (click)="exchange('forgotPassword')" mat-button color="primary">Forgot Password</button>
           </div> -->
               </form>
           </div>
           <mat-dialog-content style=" MARGIN: 0PX; 
           padding: 0px;"  ngStyle.lt-md="max-height: 100%;">
           <div class="signform " *ngIf="type=='register'">
               <form [formGroup]="registerForm" autocomplete="off">
                   <div class="field padding-10 margin-t20">

                       <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px"
                       fxLayoutGap.sm="0px">
                       <mat-form-field fxFlex appearance="outline">
                           <mat-label>First Name</mat-label>
                           <input trim="blur"   name="new-label"  [autocomplete]="'off'" autocomplete="off"
                               style="font-size: initial;" matInput formControlName="firstName" required>
                           <mat-error *ngFor="let validation of createUserValidations.firstName">
                               <mat-error class="error-message"
                                   *ngIf="registerForm.get('firstName').hasError(validation.type) && (registerForm.get('firstName').dirty || registerForm.get('firstName').touched) ">
                                   {{validation.message}}
                               </mat-error>
                           </mat-error>
                       </mat-form-field>



                   </div>
                  

                   <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px"
                   fxLayoutGap.sm="0px">
                   <mat-form-field fxFlex appearance="outline">
                       <mat-label>Last Name</mat-label>
                       <input trim="blur"   name="new-label"  [autocomplete]="'off'" autocomplete="off"
                           style="font-size: initial;" matInput formControlName="lastName" required>
                       <mat-error *ngFor="let validation of createUserValidations.lastName">
                           <mat-error class="error-message"
                               *ngIf="registerForm.get('lastName').hasError(validation.type) && (registerForm.get('lastName').dirty || registerForm.get('lastName').touched) ">
                               {{validation.message}}
                           </mat-error>
                       </mat-error>
                   </mat-form-field>
                   </div>
                       <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px"
                           fxLayoutGap.sm="0px">
                           <mat-form-field fxFlex appearance="outline">
                               <mat-label>E-Mail</mat-label>
                               <input trim="blur"   name="new-label"  [autocomplete]="'off'" autocomplete="off"
                                   style="font-size: initial;" matInput formControlName="email" required>
                               <mat-error *ngFor="let validation of createUserValidations.email">
                                   <mat-error class="error-message"
                                       *ngIf="registerForm.get('email').hasError(validation.type) && (registerForm.get('email').dirty || registerForm.get('email').touched) ">
                                       {{validation.message}}
                                   </mat-error>
                               </mat-error>
                           </mat-form-field>

                       </div>
                       <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="10px"
                           fxLayoutGap.sm="0px">
                           <mat-form-field fxFlex appearance="outline">
                               <mat-label>Mobile Number</mat-label> 
                               <input    trim="blur"   name="new-label"   (input)="checkContains('telephone','registerForm')"  [autocomplete]="'off'" matInput formControlName="telephone"   minlength="10" maxlength="10"
                                   required>
                               <mat-error *ngFor="let validation of createUserValidations.telephone;let i=index" >


                                   <mat-error  class="error-message"
                                       *ngIf="registerForm.get('telephone').hasError(validation.type) && (registerForm.get('telephone').dirty || registerForm.get('telephone').touched) ">
                                       {{validation.message}}
                                   </mat-error>

                               </mat-error>
                           </mat-form-field>
                           <!-- <mat-form-field fxFlex appearance="outline">
                               <mat-label>Gender</mat-label>
                               <mat-select formControlName="gender">
                                   <mat-option *ngFor="let gen of genders" [value]="gen.value">
                                       {{gen.viewValue}}
                                   </mat-option>
                               </mat-select>
                               <mat-error *ngFor="let validation of createUserValidations.gender">
                                   <mat-error class="error-message"
                                       *ngIf="registerForm.get('gender').hasError(validation.type) && (registerForm.get('gender').dirty || registerForm.get('gender').touched) ">
                                       {{validation.message}}
                                   </mat-error>
                               </mat-error>
                           </mat-form-field> -->
                       </div>

                       <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.gt-sm="10px">
                           <div fxLayout.lt-sm="column" fxFlex.gt-sm="50" fxFlex.lt-sm>
                               <mat-form-field appearance="outline">
                                   <mat-label>Password</mat-label>
                                   <input trim="blur"   name="new-label"  [autocomplete]="'off'" matInput formControlName="password" type="password"  required #passwordField>
                                   <button mat-icon-button
                                       (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                                       tabindex="-1" matSuffix>
                                       <mat-icon *ngIf="passwordField.type === 'password'">visibility</mat-icon>
                                       <mat-icon *ngIf="passwordField.type === 'text'">visibility_off</mat-icon>
                                   </button>
                                   <mat-error *ngIf="registerForm.controls.password.errors?.required">
                                       Please fill out this field.
                                      </mat-error>
                                   <mat-error *ngIf="registerForm.controls.password.hasError('minlength')&&passwordValidationRequired==0">
                                   Should Contain atleast {{passwordMinimumCharacters}} Characters.!
                                   </mat-error>
                               </mat-form-field>
                               <div fxLayout="column"  *ngIf="passwordValidationRequired == 1">
                                   <h6 fxLayout="row"
                                       [ngClass]="registerForm.controls['password'].hasError('required') || registerForm.controls['password'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                                       <i class="material-icons matIcon">{{
                                           registerForm.controls['password'].hasError('required') ||
                                           registerForm.controls['password'].hasError('minlength') ? 'cancel' :
                                           'check_circle' }}</i>
                                       &nbsp; Should Contain atleast {{passwordMinimumCharacters}} Characters.!
                                   </h6>
                                   <h6 fxLayout="row"
                                       [ngClass]="registerForm.controls['password'].hasError('required') || registerForm.controls['password'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                                       <i class="material-icons matIcon">{{
                                           registerForm.controls['password'].hasError('required') ||
                                           registerForm.controls['password'].hasError('hasNumber') ? 'cancel' :
                                           'check_circle' }}</i>
                                       &nbsp;Contain at least 1 number!
                                   </h6>

                                   <h6
                                       [ngClass]="registerForm.controls['password'].hasError('required') || registerForm.controls['password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                                       <i class="material-icons matIcon">{{
                                           registerForm.controls['password'].hasError('required') ||
                                           registerForm.controls['password'].hasError('hasCapitalCase') ? 'cancel' :
                                           'check_circle' }}</i>
                                       Contain at least 1 in Capital Case!
                                   </h6>
                                   <h6
                                       [ngClass]="registerForm.controls['password'].hasError('required') || registerForm.controls['password'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                                       <i class="material-icons matIcon">{{
                                           registerForm.controls['password'].hasError('required') ||
                                           registerForm.controls['password'].hasError('hasSmallCase') ? 'cancel' :
                                           'check_circle' }}</i>
                                       Contain at least 1 Letter in Small Case!
                                   </h6>
                                   <h6
                                       [ngClass]="registerForm.controls['password'].hasError('required') || registerForm.controls['password'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
                                       <i class="material-icons matIcon">{{
                                           registerForm.controls['password'].hasError('required') ||
                                           registerForm.controls['password'].hasError('hasSpecialCharacters') ? 'cancel' :
                                           'check_circle' }}</i>
                                       Contain at least 1 Special Character!
                                   </h6>

                               </div>
                           </div>
                           <div fxLayout.lt-sm="column" fxFlex.gt-sm="50" fxFlex.lt-sm>
                               <mat-form-field appearance="outline">
                                   <mat-label>Confirm Password </mat-label>
                                   <input trim="blur"   name="new-label"  [autocomplete]="'off'" matInput formControlName="confirmPassword" type="password" required
                                       #passwordFields>
                                   <button mat-icon-button 
                                       (click)="passwordFields.type === 'password' ? passwordFields.type = 'text' : passwordFields.type = 'password'"
                                       tabindex="-1" matSuffix>
                                       <mat-icon *ngIf="passwordFields.type === 'password'">visibility</mat-icon>
                                       <mat-icon *ngIf="passwordFields.type === 'text'">visibility_off</mat-icon>
                                   </button>
                                   <mat-error *ngIf="registerForm.controls.confirmPassword.errors?.required">Confirm
                                       Password
                                       required</mat-error>
                                   <mat-error *ngIf="registerForm.controls.confirmPassword.hasError('mustMatch')">
                                       Passwords
                                       do not match</mat-error>
                               </mat-form-field>
                           </div>






                       </div>
                   </div>


                   <div class="text-center">
                       <!-- <button mat-fab class="mat-elevation-z6 login-button" (click)="createUser(registerForm.value)">
                           <mat-icon>person_add</mat-icon>
                       </button> -->

                       <button class="login-button"  fxFlex [disabled]="text !='Create User'"  (click)="emailVerified()" mat-raised-button>
                           {{text}}
                           <mat-icon *ngIf="text !='Create User' ">
                               <mat-progress-spinner  diameter="20">
                               </mat-progress-spinner>
                           </mat-icon>
                       </button>
                   </div>

                   <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-2">
                       <!-- <div class="divider w-100"></div>
             <div class="divider w-100"></div> -->
                   </div>
                   <div fxLayout="row" fxLayoutAlign="center center">
                       <button mat-button color="primary-colorr" (click)="exchange('signup')">Back to Sign in</button>
                   </div>
               </form>
           
           </div>
           </mat-dialog-content>

           <!-- dbds -->

           <div class="signform" *ngIf="type=='forgotPassword'">
               <form [formGroup]="forgotPasswordForm" autocomplete="off">
                   <div class="padding-10">
                       <div fxLayout.sm="column">
                           <mat-form-field  fxFlex appearance="outline">
                               <mat-label>E-Mail</mat-label>
                               <input disabled trim="blur"   name="new-label"  [autocomplete]="'off'" [readonly]="!blurfunction" (blur)="onBlurMethod('forgotPassword')"
                                   style="font-size: initial;" matInput formControlName="email" required>
                               <button [disabled]="blurfunction" mat-icon-button (click)="changeUserName()" matSuffix>
                                   <mat-icon>edit</mat-icon>
                               </button>
                               <mat-error *ngFor="let validation of createUserValidations.email">
                                   <mat-error class="error-message"
                                       *ngIf="forgotPasswordForm.get('email').hasError(validation.type) && (forgotPasswordForm.get('email').dirty || forgotPasswordForm.get('email').touched) ">
                                       {{validation.message}}
                                   </mat-error>
                               </mat-error>
                           </mat-form-field>
                       </div>
                       <div *ngIf="!blurfunction" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
                           fxLayoutGap="10px">
                           Token Send To your Email Id
                       </div>
                       <div  fxLayout="column"  >
                           <mat-form-field style="padding:0px; margin:0px"    fxFlex="100" appearance="outline">
                               <mat-label>Token</mat-label>
                               <input trim="blur"   name="new-label"  [autocomplete]="'off'" [readonly]="disable" style="font-size: initial;"
                                   matInput formControlName="token" required>
                               <button mat-icon-button [disabled]="disable" (click)="forgotPassword(loginForm.value.username,'user','refresh')" matSuffix>
                                   <mat-icon *ngIf="!blurfunction">refresh</mat-icon>
                               </button>
                               <mat-error *ngFor="let validation of createUserValidations.token">
                                   <mat-error class="error-message"
                                   *ngIf="forgotPasswordForm.get('token').hasError(validation.type) && (forgotPasswordForm.get('token').dirty || forgotPasswordForm.get('token').touched) ">
                                   {{validation.message}}
                               </mat-error>
                               </mat-error>
                           </mat-form-field>
                           <div fxFlex="100" fxLayout="row"  >
                               <button fxFlex [disabled]="disable" mat-flat-button class="primary" style="color:white" (click)="tokenVerify()">Token
                                   Verify</button>
                           </div>
                       </div>

                       <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.gt-sm="10px">
                           <div fxLayout.lt-sm="column" fxFlex.gt-sm="50" fxFlex.lt-sm>
                               <mat-form-field appearance="outline">
                                   <mat-label>Password</mat-label>
                                   <input trim="blur"   name="new-label"  [autocomplete]="'off'" [readonly]="!disable" matInput formControlName="password"
                                       type="password" required #passwordField>
                                   <button mat-icon-button
                                       (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                                       tabindex="-1" matSuffix>
                                       <mat-icon *ngIf="passwordField.type === 'password'">visibility</mat-icon>
                                       <mat-icon *ngIf="passwordField.type === 'text'">visibility_off</mat-icon>
                                   </button>
                                   <mat-error *ngIf="forgotPasswordForm.controls.password.errors?.required">
                                       Please fill out this field.
                                      </mat-error>
                                   <mat-error *ngIf="forgotPasswordForm.controls.password.hasError('minlength')&&passwordValidationRequired==0">
                                   Should Contain atleast {{passwordMinimumCharacters}} Characters.!
                                   </mat-error>

                               </mat-form-field>
                               <div fxLayout="column" *ngIf="passwordValidationRequired == 1">
                                   <h6 fxLayout="row"
                                       [ngClass]="forgotPasswordForm.controls['password'].hasError('required') || forgotPasswordForm.controls['password'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                                       <i class="material-icons matIcon">{{
                                           forgotPasswordForm.controls['password'].hasError('required') ||
                                           forgotPasswordForm.controls['password'].hasError('minlength') ? 'cancel' :
                                           'check_circle' }}</i>
                                           &nbsp; Should Contain atleast {{passwordMinimumCharacters}} Characters.!
                                   </h6>
                                   <h6 fxLayout="row"
                                       [ngClass]="forgotPasswordForm.controls['password'].hasError('required') || forgotPasswordForm.controls['password'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                                       <i class="material-icons matIcon">{{
                                           forgotPasswordForm.controls['password'].hasError('required') ||
                                           forgotPasswordForm.controls['password'].hasError('hasNumber') ? 'cancel' :
                                           'check_circle' }}</i>
                                       &nbsp; Contain at least 1 number!
                                   </h6>

                                   <h6
                                       [ngClass]="forgotPasswordForm.controls['password'].hasError('required') || forgotPasswordForm.controls['password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                                       <i class="material-icons matIcon">{{
                                           forgotPasswordForm.controls['password'].hasError('required') ||
                                           forgotPasswordForm.controls['password'].hasError('hasCapitalCase') ? 'cancel' :
                                           'check_circle' }}</i>
                                       Contain at least 1 in Capital Case!
                                   </h6>
                                   <h6
                                       [ngClass]="forgotPasswordForm.controls['password'].hasError('required') || forgotPasswordForm.controls['password'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                                       <i class="material-icons matIcon">{{
                                           forgotPasswordForm.controls['password'].hasError('required') ||
                                           forgotPasswordForm.controls['password'].hasError('hasSmallCase') ? 'cancel' :
                                           'check_circle' }}</i>
                                       Contain at least 1 Letter in Small Case!
                                   </h6>
                                   <h6
                                       [ngClass]="forgotPasswordForm.controls['password'].hasError('required') || forgotPasswordForm.controls['password'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
                                       <i class="material-icons matIcon">{{
                                           forgotPasswordForm.controls['password'].hasError('required') ||
                                           forgotPasswordForm.controls['password'].hasError('hasSpecialCharacters') ?
                                           'cancel' :
                                           'check_circle' }}</i>
                                       Contain at least 1 Special Character!
                                   </h6>

                               </div>
                           </div>
                           <div fxLayout.lt-sm="column" fxFlex.gt-sm="50" fxFlex.lt-sm>
                               <mat-form-field appearance="outline">
                                   <mat-label>Confirm Password </mat-label>
                                   <input [readonly]="!disable" matInput formControlName="confirmPassword" type="password"
                                       required #passwordFields>
                                   <button mat-icon-button
                                       (click)="passwordFields.type === 'password' ? passwordFields.type = 'text' : passwordFields.type = 'password'"
                                       tabindex="-1" matSuffix>
                                       <mat-icon *ngIf="passwordFields.type === 'password'">visibility</mat-icon>
                                       <mat-icon *ngIf="passwordFields.type === 'text'">visibility_off</mat-icon>
                                   </button>
                                   <mat-error
                                       *ngIf="forgotPasswordForm.controls.confirmPassword.hasError('required') ">
                                       Please fill out this field.</mat-error>

                                       <mat-error *ngIf="forgotPasswordForm.controls.confirmPassword.hasError('mustMatch')">
                                           Passwords
                                           do not match</mat-error>
                               </mat-form-field>
                           </div>


                       </div>
                   </div>

                   <div class="text-center">

                       <div [ngClass]="{'overlayProduct':forgotPasswordForm.invalid}" style="padding: 0px 50px;">
                           <div class="loginButton mat-elevation-z2" matRipple
                               (click)="changePassword(forgotPasswordForm.value)" fxLayout="row"
                               fxLayoutAlign="center center">
                               <span>Confirm</span>
                           </div>
                       </div>
                   </div>

                   <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-2">
                   </div>
                   <div fxLayout="row" fxLayoutAlign="center center">
                       <button (click)="exchange('signup')" mat-button class="primary-icon">Back to Sign in</button>
                   </div>
               </form>
           </div>

           <div fxLayout="column" style="padding: 20px 50px " fxLayoutGap="5px" class="otpform"*ngIf="type=='otp'">
               <form [formGroup]="otpForm" autocomplete="off">
               <div fxLayout="row" fxLayoutAlign="center center" >
                   Please enter the OTP 
                  
               </div>
               <div fxLayout="row" fxLayoutAlign="start center">
                   <mat-form-field fxFlex appearance="outline">
                       <mat-label>Enter OTP Digit</mat-label>
                       <input  required formControlName="otp"  matInput>

                       <mat-error *ngFor="let validation of createUserValidations.otp">
                           <mat-error class="error-message"
                               *ngIf="otpForm.get('otp').hasError(validation.type) && (otpForm.get('otp').dirty || otpForm.get('otp').touched) ">
                               {{validation.message}}
                           </mat-error>
                       </mat-error>
                   </mat-form-field>
               </div>
               <div *ngIf="!change"  class="padding-b10" fxShow.sm fxShow.gt-sm fxLayout="row" fxLayoutAlign="start center">
                   <button (click)="exchange('signup1')" style="color:white" fxFlex class="primary" mat-flat-button>Change</button>
               </div>
               <div fxLayout="row" fxLayoutAlign="start center">
                   <button (click)="verifyOpt()" style="color:white" fxFlex class="primary" mat-flat-button>Verify</button>
               </div>
               <div class="padding-l10" fxLayout="row" fxLayoutAlign="center center" >
                   Not received your code?
                   <button [disabled]="disabled" (click)="resendOtp()" class="primary-icon"  mat-button>
                       Resend code
                   </button>

                   <span *ngIf="disabled">
                  {{countdown}}
                   </span>



               </div>
               </form>
           </div>

       </div>
   </div>
   </mat-dialog-content>
